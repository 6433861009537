<template>
  <div class="work">
    <div class="make" v-if="ismake">
      <template>
        <div class="login">
          <div class="form">
            <h1 class="title">{{company.name}}</h1>
            <el-form ref="ruleForm" label-width="0" size="medium">
              <el-form-item label prop="username">
                <!-- <el-input placeholder="请输入员工姓名" v-model="staff.name" autocomplete="off"></el-input> -->
                <el-input v-model.trim="staff.name" autocomplete="off" placeholder="请输入员工姓名">
                  <i slot="prefix" class="el-input__icon el-icon-user"></i>
                </el-input>
              </el-form-item>
              <el-form-item label prop="password">
                <!-- <el-input style="margin-top: 20px;" placeholder="请输入员工编号" v-model="staff.code" autocomplete="off"></el-input> -->
                <el-input v-model.trim="staff.code" autocomplete="off" placeholder="请输入员工编号">
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="submit" type="primary" @click="isstaff()">进入</el-button>
              </el-form-item>
            </el-form>
          </div>
          <!-- <div class="footerLogin">
            盘工-生产管理平台 - <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">闽ICP备18016756号-1 </a>
          </div> -->
        </div>
      </template>
      <!-- <div class="input">

        <el-input placeholder="请输入员工姓名" v-model="staff.name" autocomplete="off"></el-input>
        <el-input style="margin-top: 20px;" placeholder="请输入员工编号" v-model="staff.code" autocomplete="off"></el-input>\
        <el-button @click="isstaff" style="  width: 100%;" type="primary">进入</el-button>
      </div> -->
    </div>
    <div class="title2">
      耗材领用
    </div>
    <div class="sele">
      <span>类别：</span>
      <el-select v-model="iot_id" placeholder="请选择" @change='change_iot_id'>
        <el-option key="" label="全部" value="">
        </el-option>
        <el-option v-for="item in types" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </div>
    <!-- <div class="sele">
      <span>名称</span>
      <el-input v-model="name" autocomplete="off" placeholder="请输入名称"> </el-input>
     
    </div> -->

    <!-- 表格 -->
    <div class="tablebox">
      <el-table :data="schedules" style="width: 100%">

        <el-table-column prop="type" label="类别" min-width="15%">
        </el-table-column>
        <el-table-column prop="name" label="名称" min-width="20%">
        </el-table-column>
        <el-table-column prop="spec" label="规格" min-width="20%">
        </el-table-column>
        <el-table-column prop="safe_stock" label="安全库存" min-width="15%">
        </el-table-column>
        <el-table-column prop="surplus_stock" label="剩余库存" min-width="15%">
        </el-table-column>
        <el-table-column label="操作" min-width="20%">
          <template slot-scope="scope">
            <el-button @click="ly(scope.row)" type="text">领用</el-button>

          </template>

        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="'正在领用'+receive_name" :visible.sync="endVisible" width="80%">
      <el-form :model="endData" style="padding: 0 5px;">

        <el-form-item label="领用数量:" label-width="80px">
          <el-input-number style="width:calc(100%);" type="number" placeholder="请输入完成数量" v-model="receive_nums" autocomplete="off"></el-input-number>
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="endVisible = false">取 消</el-button>
        <el-button type="primary" @click="lyopen">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>

import axios from 'axios';
import { Message } from 'element-ui';
import Cookies from 'js-cookie';
export default {
  data () {
    return {
      company_id: '',
      iot_id: '',
      activeNames: ['1'],
      devices: [],
      schedules: [],
      staffsid: '',
      company: {},
      staffs: [],
      value: '',
      staff: {
        name: '',
        code: ''
      },
      ismake: true,
      device: {},
      schedule_id: '',
      startTime: "",
      date: new Date().valueOf(),
      timer: "",
      endVisible: false,
      endData: {
        end_time: '',
        complete_num: '',
        remark: ''
      },
      insertVisible: false,
      insertData: {
        material_name: "",
        num: '',
        remark: ''
      },
      type: '',
      name: '',
      types: [],
      receive_nums: 0,
      receive_name: '',
      receive_id: ''
    };
  },
  methods: {
    lyopen () {
      if (this.receive_nums === "") {
        this.$message.error({
          message: '请输入数量'

        });
        return;
      }
      if (this.receive_nums === 0) {
        this.$message.error({
          message: '数量不能为0'

        });
        return;
      }

      let data = {
        staff_id: JSON.parse(Cookies.get('staff')).id,
        id: this.receive_id,
        receive_nums: this.receive_nums

      };
      console.log(data);
      axios({
        method: 'POST',
        url: process.env.VUE_APP_APIHOST + '/consumable/receive',
        data
      }).then(res => {
        console.log(res);
        if (res.data.code !== 200) {
          this.$message.error({
            message: res.data.message
          });
          return;
        }
        this.$message({
          message: '操作成功',
          type: 'success'
        });

        this.gethandle(this.company_id);
        this.endVisible = false;
      }).catch(res => {
        Message({
          message: res.msg || res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        });
      });

    },
    ly (row) {
      console.log(row);
      this.receive_id = row.id;
      this.receive_nums = 0;
      this.receive_name = row.name;
      this.endVisible = true;

    },
    // 切换
    change_iot_id (value) {
      this.type = value;
      this.schedules = [];
      console.log(value);
      this.gethandle(this.company_id);
    },
    //插单
    opInsert () {
      this.insertVisible = true;
      this.insertData = {
        material_name: "",
        num: '',
        remark: ''
      };
    },
    insert () {
      let data = this.insertData;
      data.staff_id = this.staffsid;
      data.iot_id = this.iot_id;
      data.num = data.num ? Number(data.num) : '';
      data.type = 2;
      if (!data.iot_id) {
        this.$message.error({
          message: '机器不能为空'
        });
        return;
      }
      if (!data.staff_id) {
        this.$message.error({
          message: '员工不能为空'
        });
        return;
      }
      console.log(data);
      if (data.num === '' || data.num === 0) {
        this.$message.error({
          message: '数量不能为空或者0'
        });
        return;
      }
      if (data.material_name === '') {
        this.$message.error({
          message: '名称不能为空'
        });
        return;
      }
      axios({
        method: 'post',
        url: process.env.VUE_APP_APIHOST + '/staff/handle',
        data
      }).then(res => {
        console.log(res);
        if (res.data.code !== 200) {
          this.$message.error({
            message: res.data.message
          });
          return;
        }
        if (res.data.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });

          this.startTime = this.dayjs(new Date).format('YYYY-MM-DD HH:mm:ss');
          this.gethandle(this.iot_id);
          this.insertVisible = false;
        }

      }).catch(res => {
        Message({
          message: res.msg || res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        });
      });
    },
    // 开始结束
    opend () {
      this.endVisible = true;
      this.endData = {
        end_time: '',
        complete_num: '',
        remark: ''
      };
      let lidata = this.dayjs(new Date).format('YYYY-MM-DD HH:mm:ss');
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        lidata = this.dayjs(new Date).format('YYYY/MM/DD HH:mm:ss');
      } else {
        // 安卓系统
        lidata = this.dayjs(new Date).format('YYYY-MM-DD HH:mm:ss');
      }
      this.endData.end_time = lidata;


    },
    end () {
      let data = this.endData;
      if (data.complete_num === "") {
        this.$message.error({
          message: '请输入完成数量'

        });
        return;
      }
      if (!data.end_time) {
        this.$message.error({
          message: '请选择完成时间'
        });
        return;
      }
      data.log_id = this.device.log_id;
      data.iot_id = this.iot_id;
      data.complete_num = Number(data.complete_num);
      console.log(data);
      axios({
        method: 'put',
        url: process.env.VUE_APP_APIHOST + '/staff/handle/' + data.log_id,
        data
      }).then(res => {
        console.log(res);
        if (res.data.code !== 200) {
          this.$message.error({
            message: res.data.message
          });
          return;
        }
        this.$message({
          message: '操作成功',
          type: 'success'
        });

        this.gethandle(this.iot_id);
        this.endVisible = false;
      }).catch(res => {
        Message({
          message: res.msg || res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        });
      });

    },
    on () {
      let data = {};
      data.staff_id = this.staffsid;
      data.iot_id = this.iot_id;
      data.type = 1;
      data.schedule_id = this.schedule_id;
      data.material_name = this.device.curr_material_name;
      if (!data.staff_id) {
        this.$message.error({
          message: ' 员工不能为空'
        });
        return;
      }
      if (!data.iot_id) {
        this.$message.error({
          message: '机器不能为空'
        });
        return;
      }
      if (!data.schedule_id) {
        this.$message.error({
          message: '请先选择生产任务'
        });
        return;
      }
      axios({
        method: 'post',
        url: process.env.VUE_APP_APIHOST + '/staff/handle',
        data
      }).then(res => {
        console.log(res);
        if (res.data.code !== 200) {
          this.$message.error({
            message: res.data.message
          });
          return;
        }
        if (res.data.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });

          this.startTime = this.dayjs(new Date).format('YYYY-MM-DD HH:mm:ss');
          this.gethandle(this.iot_id);
        }

      }).catch(res => {
        Message({
          message: res.msg || res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        });
      });
    },
    xz (row) {
      console.log(row);
      this.device.curr_material_name = row.material_name;
      this.device.work_procedure = row.work_procedure;
      this.device.num = row.num;
      this.device.complete_num = row.complete_num;
      this.device.clamp_way = row.clamp_way;
      this.device.remark = row.remark;
      this.device.handle_staff = this.swstaff();
      this.schedule_id = row.id;

    },
    disposeDate (itme) {
      let newitme = '';
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        newitme = this.date - new Date(itme.replace(/-/g, '/')).getTime();
      } else {
        // 安卓系统
        newitme = this.date - new Date(itme).getTime();
      }
      return this.dateConvert(newitme);
    },
    dateConvert (value) {
      let hour,
        min,
        sec,
        createDate = value / 1000;
      hour = parseInt(createDate / 60 / 60);
      min = parseInt((createDate - hour * 3600) / 60);
      sec = parseInt(createDate - (hour * 3600 + min * 60));
      if (hour > 0) {
        if (min > 0) {
          if (sec > 0) {
            createDate = hour + "时" + min + "分" + sec + "秒";
          } else {
            createDate = hour + "时" + min + "分";
          }
        } else {
          if (sec > 0) {
            createDate = hour + "时" + sec + "秒";
          } else {
            createDate = hour + "时";
          }
        }
      } else if (min > 0) {
        createDate = sec > 0 ? min + "分" + sec + "秒" : min + "分";
      } else if (sec > 0) {
        createDate = sec + "秒";
      }
      return createDate;
    },
    // 验证员工信息
    swstaff () {
      let text = '';
      this.staffs.forEach(item => {
        if (item.id === this.staffsid) {
          text = item.name;
        }
      });
      return text;
    },
    isstaff () {

      this.staffs.forEach(item => {
        if (item.code === this.staff.code) {
          if (item.name === this.staff.name) {
            this.ismake = false;
            this.staffsid = item.id;
            Cookies.set('staff', item);
            Cookies.set('company', this.company.name);
          }
        }
      });
      if (this.ismake) {
        this.$message.error({
          message: '员工信息错误，无法访问'
        });
      }
    },
    gethandle (id) {
      let taht = this;
      axios({
        method: 'get',
        url: process.env.VUE_APP_APIHOST + '/consumable/handle?company_id=' + id + '&type=' + taht.type + '&name=' + taht.name + '&limit=' + 999 + '&page = 1'
      }).then(res => {
        console.log(res);
        // this.devices = res.data.data.devices;
        this.staffs = res.data.data.staffs;
        this.types = res.data.data.types;
        this.schedules = res.data.data.list;
        // this.device = res.data.data.device;
        this.company = res.data.data.company;
        // this.startTime = res.data.data.device.start_time;
        if (Cookies.get('staff') && this.company.name === Cookies.get('company')) {
          let staffdata = JSON.parse(Cookies.get('staff'));
          console.log(staffdata);
          this.staffsid = staffdata.id;
          this.ismake = false;
        }
      }).catch(res => {
        Message({
          message: res.msg || res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        });
      });
    }
  },
  mounted () {



    this.company_id = this.$route.query.company_id;
    if (this.company_id) {
      this.gethandle(this.company_id);
    }
    // const _this = this;
    // this.timer = setInterval(() => {
    //   _this.date = new Date(); // 修改数据date
    // }, 1000);

  },
  beforeDestroy () {
    // if (this.timer) {
    //   clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    // }
  }

};
</script>
<style scoped >
.work {
  background: white;
  width: 100%;
  min-height: 100%;
}
.work .title2 {
  background: #28a95c;
  color: white;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: bold;
}
.work .sele {
  width: 100%;
  float: left;
  padding: 5px 5px;
  box-sizing: border-box;

  line-height: 32px;
  background-color: #b9b6b6;
}
.work .sele .el-select {
  width: calc(100% - 60px);
}
.work .sele span {
  display: inline-block;
  width: 60px;
  font-size: 14px;
}
.work .tablebox {
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
}
.work .collapsebox {
  width: 100%;
  padding: 0px 10px;
  box-sizing: border-box;
}
.work .dataP .num {
  display: inline-block;
  width: 33.3%;
}
.work .collapsetitle {
  font-weight: bold;
  font-size: 16px;
}
.work .btnbox {
  text-align: center;
  margin-top: 30px;
}
.work .btnbox .btn {
  width: 40%;
}
.work .make {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: black;
  z-index: 999;
  text-align: center;
}
.work .make .input {
  width: 80%;
  position: absolute;
  top: 40%;
  left: 10%;
}
.el-message {
  width: 80% !important;
  min-width: 300px !important;
  box-sizing: border-box !important;
}
</style> 
<style lang="scss" scoped>
.login {
  display: flex;
  width: 100%;
  height: 100vh;
  min-height: 500px;
  overflow: auto;
  background-image: url('../../assets/images/bg-smooth.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  align-items: center;
  .form {
    flex: none;
    width: 320px;
    padding: 60px;
    margin: 0 auto;
    border-radius: 4px;
    background-color: rgba($color: #ffffff, $alpha: 0.2);
    .title {
      font-weight: 300;
      margin-top: 0;
      margin-bottom: 60px;
      text-align: center;
      color: #ffffff;
    }
    .submit {
      margin-top: 30px;
      width: 100%;
    }
  }
}
.footerLogin {
  position: fixed;
  bottom: 50px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  color: #808695;
  a {
    color: #808695;
  }
}
@media screen and (max-width: 750px) {
  .login {
    display: flex;
    width: 100%;
    height: 100vh;
    min-height: 500px;
    overflow: auto;
    background-image: url('../../assets/images/bg-smooth.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    align-items: center;
    .form {
      flex: none;
      width: 80%;
      box-sizing: content-box;
      padding: 10px;
      margin: 0 auto;
      border-radius: 4px;
      background-color: rgba($color: #ffffff, $alpha: 0.2);
      .title {
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 30px;
        text-align: center;
        color: #ffffff;
        font-size: 24px;
        font-weight: bold;
      }
      .submit {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}
</style>